const get_icon = (name) => {
  return <i className={`nav-icon ${name}`}></i>;
};

const sidebarConfig = () => {
  let sidebarMenus = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: get_icon("fas fa-tachometer-alt"),
    },
    {
      title: "Student Reports",
      path: "/student-reports",
      icon: get_icon("fa-solid fa-list"),
    },
    {
      title: "Class Room",
      path: "/class-room",
      icon: get_icon("fas fa-users"),
    },
    {
      title: "Members",
      icon: get_icon("fas fa-users"),
      child_options: [
        {
          title: "Students",
          path: "/students",
          icon: get_icon("fas fa-user-graduate"),
        },
        {
          title: "Teachers",
          path: "/teachers",
          icon: get_icon("fas fa-chalkboard-teacher"),
        },
        {
          title: "Guardians",
          path: "/guardians",
          icon: get_icon("fas fa-users"),
        },
      ],
    },
    {
      title: "Students Related",
      icon: get_icon("fas fa-user-graduate"),
      child_options: [
        {
          title: "Leave Requests",
          path: "/leave-requests",
          icon: get_icon("fa-solid fa-person-walking-arrow-right"),
        },
        {
          title: "Fees Management",
          path: "/fees-management",
          icon: get_icon("fa-solid fa-comment-dollar"),
        },
      ],
    },
    {
      title: "Reading Material",
      icon: get_icon("fas fa-book-reader"),
      child_options: [
        {
          title: "Lesson Books",
          path: "/lesson-books",
          icon: get_icon("fas fa-book-reader"),
        },
        {
          title: "Training Lesson",
          path: "/training-lesson",
          icon: get_icon("fa-solid fa-globe"),
        },
      ],
    },

    {
      title: "Settings",
      icon: get_icon("fa-solid fa-gear"),
      child_options: [
        {
          title: "General Setting",
          path: "/general-setting",
          icon: get_icon("fa-solid fa-gear"),
        },
        {
          title: "Stripe Setting",
          path: "/stripe-setting",
          icon: get_icon("fa-solid fa-gear"),
        },
        {
          title: "Email Setting",
          path: "/email-setting",
          icon: get_icon("fa-solid fa-gear"),
        },
      ],
    },
    {
      title: "Extras",
      icon: get_icon("fa-regular fa-file-lines"),
      child_options: [
        {
          title: "Personal Notes",
          path: "/personal-notes",
          icon: get_icon("fa-solid fa-book"),
        },
        {
          title: "To Do Lists",
          path: "/to-do-lists",
          icon: get_icon("fa-solid fa-list"),
        },
      ],
    },
    {
      title: "Announcements",
      icon: get_icon("fas fa-bullhorn"),
      child_options: [
        {
          title: "For Me",
          path: "/announcements",
          icon: get_icon("fas fa-bullhorn"),
        },
        {
          title: "Templates",
          path: "/announcement-templates",
          icon: get_icon("fas fa-bullhorn"),
        },
        {
          title: "Announcements",
          path: "/announcements-list",
          icon: get_icon("fas fa-bullhorn"),
        },
      ],
    },
    {
      title: "Pages",
      path: "/web-pages",
      icon: get_icon("fa-regular fa-file-lines"),
    },
  ];

  return sidebarMenus;
};
export default sidebarConfig;
