import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  get_email_settings_api,
  update_email_settings_api,
} from "../../DAL/EmailSetting/EmailSetting";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ChooseImage from "../../components/GeneralComponents/ChooseImage";
import { s3baseUrl } from "../../config/config";
import { upload_general_image_api } from "../../DAL/general_routes/general_routes";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const EMPTY_VALUES = {
  email: "",
  password: "",
  footer_text: "",
  host_service_name: "gmail",
  host_server: "smtp.gmail.com",
  brand_logo: "",
};

const EMPTY_OBJECT = {
  media_link: "",
  media_logo: "",
  media_name: "",
};

export default function EmailSetting() {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [inputs, setInputs] = useState(EMPTY_VALUES);
  const [mediaList, setMediaList] = useState([]);

  const handleAddClick = (index) => {
    if (index) {
      setMediaList([
        ...mediaList.slice(0, index),
        { ...EMPTY_OBJECT },
        ...mediaList.slice(index),
      ]);
    } else {
      setMediaList([EMPTY_OBJECT]);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...mediaList];
    list.splice(index, 1);
    setMediaList(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      email: inputs.email,
      password: inputs.password,
      footer_text: inputs.footer_text,
      host_service_name: inputs.host_service_name,
      host_server: inputs.host_server,
      brand_logo: inputs.brand_logo,
      social_media: mediaList,
    };
    const result = await update_email_settings_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpload = async (event) => {
    const { name, files } = event.target;
    if (files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await upload_general_image_api(formData);
      if (result.code === 200) {
        let image_path = result.image_path;
        setInputs((old) => ({ ...old, [name]: image_path }));
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleChangeMedia = (values, index) => {
    const { value, name } = values;
    const list = [...mediaList];
    list[index][name] = value;
    setMediaList(list);
  };

  const handleRemoveSocialImage = (index) => {
    const list = [...mediaList];
    list[index].media_logo = "";
    setMediaList(list);
  };

  const handleUploadMedia = async (event, index) => {
    const { name, files } = event.target;
    if (files[0]) {
      const formData = new FormData();
      formData.append("image", files[0]);
      const result = await upload_general_image_api(formData);
      if (result.code === 200) {
        let image_path = result.image_path;
        setInputs((old) => ({ ...old, [name]: image_path }));
        let values = { value: image_path, name };
        handleChangeMedia(values, index);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((old) => ({ ...old, [name]: value }));
    if (name === "host_service_name") {
      let host_server = "";
      if (value === "gmail") {
        host_server = "smtp.gmail.com";
      } else if (value === "titan") {
        host_server = "smtp.titan.email";
      }
      setInputs((old) => ({ ...old, host_server: host_server }));
    }
  };

  const handleClickShowPassword = () => {
    let password_type = "text";
    if (passwordType === "text") {
      password_type = "password";
    }
    setPasswordType(password_type);
  };

  const get_email_settings = async () => {
    const result = await get_email_settings_api();
    if (result.code === 200) {
      if (result.email_setting) {
        setInputs(result.email_setting);
        if (result.email_setting.social_media?.length > 0) {
          setMediaList(result.email_setting.social_media);
        }
      }
      setIsLoadingPage(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingPage(false);
    }
  };

  useEffect(() => {
    get_email_settings();
  }, []);

  if (isLoadingPage) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid new-memories">
        <form onSubmit={handleSubmit}>
          <div className="row input-form">
            <div className="col-12">
              <h2 className="mt-4 mb-0">Email Setting</h2>
            </div>
            <div className="col-12 mt-4">
              <div className="theme--section">
                <div className="row">
                  <div className="col-12">
                    <h4>Server Configuration</h4>
                  </div>
                  <div className="col-12 col-md-6 mt-2">
                    <TextField
                      id="email-basic"
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                      name="email"
                      value={inputs.email}
                      onChange={handleChange}
                      className="form-control"
                      required={true}
                    />
                  </div>

                  <div className="col-12 col-md-6 mt-4 mt-md-2">
                    <TextField
                      type={passwordType}
                      className="form-control"
                      id="password"
                      label="Password"
                      variant="outlined"
                      name="password"
                      value={inputs.password}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              className="visibility-password"
                            >
                              {passwordType === "text" ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <FormControl fullWidth className="form-control">
                      <InputLabel id="demo-simple-select-label">
                        Host Server Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="host_service_name"
                        value={inputs.host_service_name}
                        label="Host Server Name"
                        onChange={handleChange}
                      >
                        <MenuItem value="gmail">Gmail</MenuItem>
                        <MenuItem value="titan">Hostinger (Titan)</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-12 col-md-6 mt-4">
                    <TextField
                      id="host_server"
                      label="Host Server"
                      variant="outlined"
                      fullWidth
                      name="host_server"
                      value={inputs.host_server}
                      onChange={handleChange}
                      className="form-control"
                      required={true}
                      disabled={
                        inputs.host_service_name === "gmail" ||
                        inputs.host_service_name === "titan"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="theme--section">
                <div className="row">
                  <div className="col-12">
                    <h4>Email Content</h4>
                  </div>
                  <div className="col-12 col-lg-6 mt-2">
                    <ChooseImage
                      image_path={
                        inputs.brand_logo ? s3baseUrl + inputs.brand_logo : ""
                      }
                      handleUpload={handleUpload}
                      name="brand_logo"
                      label="Brand Logo"
                    />
                  </div>
                  <div className="col-12 col-lg-6 mt-4 mt-lg-2">
                    <TextField
                      id="footer_text-basic"
                      label="Footer Text"
                      variant="outlined"
                      fullWidth
                      name="footer_text"
                      value={inputs.footer_text}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="theme--section">
                <div className="row">
                  <div className="col-12">
                    <h4>Social Media Content</h4>
                  </div>
                  {mediaList.length > 0 ? (
                    mediaList.map((media, index) => {
                      return (
                        <>
                          <div
                            className={`col-12 col-lg-2 ${
                              index === 0 ? "mt-2" : "mt-4"
                            }`}
                          >
                            <TextField
                              id={`email-basic-key-${index}`}
                              label="Media Name"
                              variant="outlined"
                              fullWidth
                              name="media_name"
                              value={media.media_name}
                              onChange={(e) =>
                                handleChangeMedia(e.target, index)
                              }
                              className="form-control"
                              required={true}
                            />
                          </div>

                          <div
                            className={`col-12 col-lg-5 ${
                              index === 0 ? "mt-4 mt-lg-2" : "mt-4"
                            }`}
                          >
                            <ChooseImage
                              image_path={
                                media.media_logo
                                  ? s3baseUrl + media.media_logo
                                  : ""
                              }
                              handleUpload={(e) => handleUploadMedia(e, index)}
                              handleRemove={() =>
                                handleRemoveSocialImage(index)
                              }
                              name="media_logo"
                              label="Media Logo"
                            />
                          </div>
                          <div
                            className={`col-10 col-lg-4 ${
                              index === 0 ? "mt-4 mt-lg-2" : "mt-4"
                            }`}
                          >
                            <TextField
                              id={`media_link-key-${index}`}
                              label="Media Link"
                              variant="outlined"
                              fullWidth
                              name="media_link"
                              value={media.media_link}
                              onChange={(e) =>
                                handleChangeMedia(e.target, index)
                              }
                              className="form-control"
                              required={true}
                            />
                          </div>
                          <div
                            className={`col-2 col-md-1 ${
                              index === 0 ? "mt-4 mt-lg-2" : "mt-4"
                            }`}
                          >
                            <div>
                              <AddCircleOutlineIcon
                                className="add--icon--svg"
                                onClick={() => handleAddClick(index + 1)}
                              />
                            </div>
                            <div>
                              {mediaList.length > 0 && (
                                <RemoveCircleOutlineIcon
                                  className="remove--icon--svg"
                                  onClick={() => handleRemoveClick(index)}
                                />
                              )}
                            </div>
                          </div>
                          <hr className="d-block d-lg-none mb-0 mt-4" />
                        </>
                      );
                    })
                  ) : (
                    <>
                      <div className="col-11 d-none d-md-block"></div>
                      <div className="col-2 col-lg-1 mt-4 mt-lg-2">
                        <div>
                          <AddCircleOutlineIcon
                            className="add--icon--svg"
                            onClick={handleAddClick}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="add--button text-end mt-3">
            <button disabled={isLoading}>
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
