import React from "react";
import { baseUrl } from "../../config/config";
import { generateRandomID } from "../../utils/constant";

export default function ChooseImage(props) {
  const { image_path, handleUpload, name, label, handleRemove } = props;

  const handle_check_link = (link) => {
    return link.replace(baseUrl, "");
  };

  let random_id = generateRandomID(20);

  return (
    <div className="d-flex">
      <div className="choose-image-container">
        <div className="choose-image-label">{label}</div>
        <div className="mt-2">
          <input
            color="primary"
            accept="image/*"
            type="file"
            id={random_id}
            style={{ display: "none" }}
            name={name}
            onChange={handleUpload}
          />
          <label className="choose-file-box" htmlFor={random_id}>
            <span className="choose-file-button">
              <i className="fa-solid fa-arrow-up-from-bracket me-2"></i>Choose
              File
            </span>
          </label>
        </div>
      </div>
      {handle_check_link(image_path).trim() && (
        <div className="image-preview-box">
          {handleRemove && (
            <span className="preview-cross-sign" onClick={handleRemove}>
              x
            </span>
          )}
          <a href={image_path} target="_blank" rel="noopener noreferrer">
            <img src={image_path} alt="" />
          </a>
        </div>
      )}
    </div>
  );
}
