import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { conversations_list_api } from "../../DAL/Chatting/Chatting";
import { useSnackbar } from "notistack";
import { useContentSetting } from "../../Hooks/ContentSetting";
import Conversations from "../../components/Chatting/Conversations";
import UserCard from "../../components/Chatting/UserCard";
import ChattingMessages from "../../components/Chatting/ChattingMessages";
import {
  messages_list_api,
  read_messages_api,
} from "../../DAL/Messages/Messages";
import {
  markdown_conversion,
  sort_array_based_on_createdAt,
} from "../../utils/constant";
import { s3baseUrl } from "../../config/config";
import { logo } from "../../assets";
import { useParams } from "react-router-dom";
import InputEditor from "../../components/GeneralComponents/InputEditor";
import ConfirmationPopup from "../../components/GeneralComponents/ConfirmationPopup";

const sum_unread_messages = (conversation_data, userIdToSum) => {
  let totalUnreadMessages = 0;
  conversation_data.forEach((conversation) => {
    const member = conversation.members.find(
      (member) => member._id === userIdToSum
    );
    if (member) {
      totalUnreadMessages += member.unread_message_count;
    }
  });
  return totalUnreadMessages;
};

export default function Chatting() {
  const { enqueueSnackbar } = useSnackbar();
  const { conversation_id } = useParams();
  const { userInfo, socket, setUnreadMessageCount } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const [conversationsList, setConversationsList] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [messagesList, setMessagesList] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [selectedObject, setSelectedObject] = useState({});
  const [openDelete, setOpenDelete] = useState(false);

  const handleSelected = (user) => {
    setSelectedConversation(user);
  };

  const scroll_to_bottom_on_message = () => {
    const chat_box = document.getElementById("chat-box-body");
    setTimeout(() => {
      if (chat_box) {
        if (isLoadingFirst) {
          chat_box.scrollTop = chat_box.scrollHeight;
          setIsLoadingFirst(false);
        } else {
          chat_box.scroll({ top: chat_box.scrollHeight, behavior: "smooth" });
        }
      }
    }, 100);
  };

  const get_conversations_list = async (type = "all") => {
    const result = await conversations_list_api(type);
    if (result.code === 200) {
      setConversationsList(result.conversations_list);
      if (conversation_id && result.conversations_list.length > 0) {
        let find_conversation = result.conversations_list.find(
          (chat) => chat._id === conversation_id
        );

        if (find_conversation) {
          setSelectedConversation(find_conversation);
        }
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_messages_list = async () => {
    setIsLoadingMessages(true);
    setIsLoadingFirst(true);
    const result = await messages_list_api(selectedConversation._id);
    if (result.code === 200) {
      setMessagesList(result.messages_list);
      read_messages_api(selectedConversation._id); //calling api for read messages
      setConversationsList((chat) => {
        return chat.map((old) => {
          let members = old.members.map((member) => {
            if (member._id === userInfo._id) {
              return { ...member, unread_message_count: 0 };
            }
            return member;
          });
          if (old._id === selectedConversation._id) {
            return { ...old, members: members };
          }
          return old;
        });
      });
      setIsLoadingMessages(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMessages(false);
    }
  };

  const showNotification = (data) => {
    let find_student = data.members.find(
      (user) => user.user_type === "student"
    );
    let find_member = data.members.find(
      (member) => member._id === userInfo._id
    );
    let notification_title = `${find_member.name} messages to ${find_student.name}`;

    let profile_image = logo;
    if (find_student?.profile_image) {
      profile_image = s3baseUrl + find_student?.profile_image;
    }
    let converted_message = data.message.message
      ? markdown_conversion(data.message.message)
      : "Photo";
    new Notification(notification_title, {
      body: converted_message,
      icon: profile_image,
    });
  };

  const getNotificationPermission = (dataObject) => {
    if (Notification.permission === "granted") {
      showNotification(dataObject);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          showNotification(dataObject);
        }
      });
    }
  };

  const handle_message_receiver = (result) => {
    if (result.code === 200) {
      const { members } = result;
      let conversation_id = result.message.conversation_id;
      let sender_id = result.message.sender_id;
      if (selectedConversation?._id === conversation_id) {
        setMessagesList((old) => [...old, result.message]);
        read_messages_api(selectedConversation._id); //calling api for read messages
        setSelectedConversation((old) => ({ ...old, is_typing: false }));
      }
      const findStudent = members.find(
        (member) => member.user_type === "student"
      );

      if (
        (!selectedConversation ||
          selectedConversation?._id !== conversation_id) &&
        result.message.message_type !== "call" &&
        findStudent._id === sender_id
      ) {
        getNotificationPermission(result);
      }
      setConversationsList((chat) => {
        return chat.map((old) => {
          let members = old.members.map((member) => {
            if (result.message.message_type === "call") return member;
            if (findStudent._id !== sender_id) return member;
            if (selectedConversation?._id !== old._id) {
              return {
                ...member,
                unread_message_count: member.unread_message_count + 1,
              };
            }
            return member;
          });

          if (old._id === conversation_id) {
            return { ...old, last_message: result.message, members: members };
          }
          return old;
        });
      });
      if (sender_id === userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const delete_message_from_class_receiver = (result) => {
    if (result.code === 200) {
      setMessagesList((old) =>
        old.filter((message) => message._id !== result.data.message_id)
      );

      if (result.is_last_message) {
        setConversationsList((chat) => {
          return chat.map((old) => {
            if (old._id === result.data.conversation_id) {
              return {
                ...old,
                last_message: result.last_message,
              };
            } else {
              return old;
            }
          });
        });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_update_message_receiver = (result) => {
    if (result.code === 200) {
      if (selectedConversation?._id === result.data.conversation_id) {
        setMessagesList((messages) => {
          return messages.map((old) => {
            if (old._id === result.last_message._id) {
              return result.last_message;
            } else {
              return old;
            }
          });
        });
      }

      if (result.is_last_message) {
        setConversationsList((chat) => {
          return chat.map((old) => {
            if (old._id === result.data.conversation_id) {
              return {
                ...old,
                last_message: result.last_message,
              };
            } else {
              return old;
            }
          });
        });
      }
      if (result.data.sender_id === userInfo._id) {
        setMessageText("");
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handle_member_online_offline = (data) => {
    const { conversation, type } = data;
    if (type !== "student") return;
    if (selectedConversation?._id === data.conversation._id) {
      setSelectedConversation((old) => {
        old.members = conversation.members;
        return old;
      });
    }
    setConversationsList((conversations) =>
      conversations.map((old) => {
        if (old._id === data.conversation._id) {
          return data.conversation;
        } else {
          return old;
        }
      })
    );
  };

  const update_call_status = (values, status) => {
    const chat_message = values.message;
    let last_call_id = chat_message._id;
    setMessagesList((old) => {
      return old.map((message) => {
        if (message._id === last_call_id) {
          if (status === "ended") {
            return chat_message;
          }
          return { ...message, call_status: status };
        }
        return message;
      });
    });
  };

  const typing_message_receiver = (data) => {
    const { conversation } = data;
    if (selectedConversation?._id === conversation._id) {
      setSelectedConversation((old) => ({ ...old, is_typing: true }));
      setTimeout(() => {
        setSelectedConversation((old) => ({ ...old, is_typing: false }));
      }, 2000);
    }
  };

  const update_message_status = (data) => {
    const { conversation_id, message_status } = data;
    if (selectedConversation?._id === conversation_id) {
      setMessagesList((old) =>
        old.map((message) => {
          let old_message_status = message.message_status;
          if (message_status === "seen") {
            message.message_status = message_status;
          } else {
            if (old_message_status === "sent") {
              message.message_status = message_status;
            }
          }
          return message;
        })
      );
    }
    setConversationsList((conversations) =>
      conversations.map((old) => {
        if (old._id === conversation_id) {
          if (old.last_message) {
            let old_message_status = old.last_message.message_status;
            if (message_status === "seen") {
              old.last_message = {
                ...old.last_message,
                message_status: message_status,
              };
            } else {
              if (old_message_status === "sent") {
                old.last_message = {
                  ...old.last_message,
                  message_status: message_status,
                };
              }
            }
          }
        }
        return old;
      })
    );
  };

  const handleAgreeDelete = (data) => {
    setSelectedObject(data);
    setOpenDelete(true);
  };

  const handleDelete = () => {
    setOpenDelete(false);
    let formData = {
      conversation_id: selectedConversation?._id,
      sender_id: userInfo._id,
      sender_name: userInfo.name,
      message_id: selectedObject._id,
      user_type: "associate",
    };
    socket.emit("delete_message_from_class", formData);
  };

  const handleMenu = () => {
    const MENU_OPTIONS = [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      },
    ];
    return MENU_OPTIONS;
  };

  useEffect(() => {
    get_conversations_list();
  }, [conversation_id]);

  useEffect(() => {
    scroll_to_bottom_on_message();
  }, [messagesList]);

  useEffect(() => {
    if (selectedConversation) {
      get_messages_list();
    }

    socket.on("send_message_from_class_receiver", (data) => {
      handle_message_receiver(data);
    });
    socket.on("delete_message_from_class_receiver", (data) => {
      delete_message_from_class_receiver(data);
    });
    socket.on("update_message_from_class_receiver", (data) => {
      handle_update_message_receiver(data);
    });
    socket.on("update_message_status", (data) => {
      update_message_status(data);
    });
    socket.on("typing_message", (data) => {
      typing_message_receiver(data);
    });
    socket.on("member_online", (data) => {
      handle_member_online_offline(data);
    });
    socket.on("member_offline", (data) => {
      handle_member_online_offline(data);
    });
    socket.on("call:ringing", (data) => {
      update_call_status(data, "ringing");
    });
    socket.on("call:accepted", (data) => {
      update_call_status(data, "started");
    });
    socket.on("call:canceled", (data) => {
      update_call_status(data, "canceled");
    });
    socket.on("call:declined", (data) => {
      update_call_status(data, "declined");
    });
    socket.on("call:ended", (data) => {
      update_call_status(data, "ended");
    });
    socket.on("call:no_answered", (data) => {
      update_call_status(data, "no_answered");
    });
    socket.on("call:unavailable", (data) => {
      update_call_status(data, "unavailable");
    });
    socket.on("permissions_error", (data) => {
      update_call_status(data, "permissions_error");
    });

    return () => {
      socket.off("send_message_from_class_receiver");
      socket.off("update_message_from_class_receiver");
      socket.off("delete_message_from_class_receiver");
      socket.off("update_message_status");
      socket.off("typing_message");
      socket.off("member_online");
      socket.off("member_offline");
    };
  }, [selectedConversation?._id]);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  const inputData = {
    messageText,
    setMessageText,
    selectedConversation,
  };

  sort_array_based_on_createdAt(conversationsList);
  setUnreadMessageCount(sum_unread_messages(conversationsList, userInfo._id));

  const conversationsData = {
    conversationsList,
    handleSelected,
    selectedConversation,
    get_conversations_list,
  };

  return (
    <>
      <div className="container-fluid pt-4">
        <div className="row">
          <div className="col-md-4">
            <Conversations {...conversationsData} />
          </div>
          <div className="col-md-8">
            <div className="chatting-section">
              {selectedConversation && (
                <UserCard
                  selectedConversation={selectedConversation}
                  messagesList={messagesList}
                  isLoadingMessages={isLoadingMessages}
                />
              )}
              <ChattingMessages
                selectedConversation={selectedConversation}
                messagesList={messagesList}
                isLoadingMessages={isLoadingMessages}
                handleMenu={handleMenu}
              />
              {selectedConversation && <InputEditor {...inputData} />}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        title={"Are you sure you want to delete this message?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
