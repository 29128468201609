import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CustomMUITable from "../../components/GeneralComponents/CustomMUITable/CustomMUITable";
import { Link, useParams } from "react-router-dom";
import {
  manage_student_access_api,
  students_list_by_teacher_api,
} from "../../DAL/Teachers/Teachers";

export default function StudentsList() {
  const { enqueueSnackbar } = useSnackbar();
  const { teacher_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [studentsList, setStudentsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [teacherInfo, setTeacherInfo] = useState({});

  const get_students_list = async () => {
    const result = await students_list_by_teacher_api(teacher_id);
    if (result.code === 200) {
      setStudentsList(result.all_students);
      setSelected(result.assigned_students);
      setTeacherInfo(result.teacher);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    setIsLoadingForm(true);
    const student_ids = selected.map((student) => student._id);
    const postData = { student_ids };
    const result = await manage_student_access_api(postData, teacher_id);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoadingForm(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingForm(false);
    }
  };

  const TABLE_HEAD = [
    { id: "number", label: "#", type: "number" },
    { id: "roll_number_string", label: "Student ID" },
    { id: "name", label: "Name" },
    {
      label: "Teachers",
      renderData: (row) => {
        if (row.teachers?.length > 0) {
          return (
            <div>
              {row.teachers.map((teacher) => {
                return <div>{teacher.name}</div>;
              })}
            </div>
          );
        }
      },
    },
    { id: "status", label: "Status", type: "row_status" },
  ];

  useEffect(() => {
    get_students_list();
  }, []);

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <div className="theme-card p-3">
              <div className="back-button-title mb-3">
                <div className="back-button-box">
                  <Link to={`/teachers`}>
                    <i className="fas fa-arrow-left"></i>
                  </Link>
                </div>
                <div className="table-title">
                  {teacherInfo.name + " (" + teacherInfo.email + ")"}
                </div>
              </div>
              <div className="light-border-bottom">
                <div className="mb-2 mui-table-heading">
                  <h2>Students List</h2>
                </div>
              </div>
              <CustomMUITable
                TABLE_HEAD={TABLE_HEAD}
                data={studentsList}
                checkbox_selection={true}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </div>
          <div className="add--button my-3 text-end">
            <button onClick={handleUpdate} disabled={isLoadingForm}>
              {`${isLoadingForm ? "Updating..." : "Update"}`}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
